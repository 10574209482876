import { default as calendrierxzbinl0to0Meta } from "/opt/buildhome/repo/pages/app/calendrier.vue?macro=true";
import { default as indexIbNDbq129sMeta } from "/opt/buildhome/repo/pages/app/index.vue?macro=true";
import { default as cameraGxFio15UzsMeta } from "/opt/buildhome/repo/pages/app/theme/[date]/camera.vue?macro=true";
import { default as indexrEg444kN8wMeta } from "/opt/buildhome/repo/pages/app/theme/[date]/index.vue?macro=true";
export default [
  {
    name: "calendrier",
    path: "/calendrier",
    component: () => import("/opt/buildhome/repo/pages/app/calendrier.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: "theme-date-camera",
    path: "/theme/:date()/camera",
    component: () => import("/opt/buildhome/repo/pages/app/theme/[date]/camera.vue").then(m => m.default || m)
  },
  {
    name: "theme-date",
    path: "/theme/:date()",
    component: () => import("/opt/buildhome/repo/pages/app/theme/[date]/index.vue").then(m => m.default || m)
  }
]